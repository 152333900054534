import React from "react";
import "./GamePage.css";
import img from "../../images/Blackout-Bingo-main.webp";
import qr from "../../images/Blackout-Bingo-qr.webp";
import images from "../../images/Blackout-Bingo-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameFive = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="Blackout Bingo"
              alt="Blackout Bingo"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">Blackout Bingo</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.4</p>
                </div>
                <p className="t-20">
                  Witamy w Blackout Bingo, zupełnie nowej grze w stylu Bingo, w
                  której możesz wygrać Real World Rewards i Cash Prizes (jeśli
                  są dostępne)! Daub Fast i wykorzystaj zabawne wzmocnienia, aby
                  podbić świat Bingo. Podróżuj po świecie i graj w niesamowitych
                  i egzotycznych miejscach.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameFive;
