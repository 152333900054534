import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import inst from "../../images/inst.svg";
import telegram from "../../images/telegrem.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="zaid-footer">
      <div className="zaid-footer__body pad">
        <div className="zaid-footer__left">
          <p className="t-18">
            zaidimuautomatai.com wykorzystuje pliki cookie, aby zapewnić
            najlepszą możliwą obsługę. Pozostając na stronie, akceptujesz
            używanie przez nas plików cookie
          </p>
          <Link to="/cookies" className="zaid-footer__link t-16 fw-500">
            Przeczytaj więcej
          </Link>
        </div>
        <div className="zaid-footer__right">
          <Link to="/privacy" className="zaid-footer__link t-16 fw-500">
          Polityka prywatności
          </Link>
          <div className="zaid-footer__social">
            <Link to="https://www.instagram.com/" target="_blank">
              <img src={inst} alt="instagram icon" title="instagram icon" />
            </Link>
            <Link to="https://web.telegram.org/" target="_blank">
              <img src={telegram} alt="telegram icon" title="telegram icon" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
