import React from "react";
import "./GamePage.css";
import img from "../../images/Cribbage-Blitz-main.webp";
import qr from "../../images/Cribbage-Blitz-qr.webp";
import images from "../../images/Cribbage-Blitz-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameSeven = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="Cribbage Blitz"
              alt="Cribbage Blitz"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">Cribbage Blitz</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.7</p>
                </div>
                <p className="t-20">
                  Cribbage Blitz to połączenie pasjansa i krykieta. To doskonały
                  sposób na ćwiczenie umiejętności krykieta, trenowanie mózgu
                  lub po prostu zabijanie czasu. Jak grać • Graj jedną talią
                  kart, aby zdobyć jak najwięcej punktów. • Licz każdą rękę i
                  cribuj, aby zdobyć punkty. • Zdobywaj punkty w wyjątkowym
                  formacie heads-up, w którym możesz liczyć każdy ruch punktowy.
                  • Uważaj na swoje liczenia - przegapisz cokolwiek, a otrzymasz
                  karę muggins.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameSeven;
