import React from "react";
import { useLocation } from "react-router-dom";
import "./Reviews.css";
import stars from "../../images/stars.svg";
import rev1 from "../../images/rev-1.webp";
import rev2 from "../../images/rev-2.webp";
import rev3 from "../../images/rev-3.webp";

const Reviews = () => {
  const location = useLocation();
  return (
    <section className="zaid-reviews pad">
      {location.pathname === "/reviews" ? (
        <h1 className="zaid-reviews__header">Recenzje</h1>
      ) : (
        <h2 className="zaid-reviews__header">Recenzje</h2>
      )}
      <ul className="zaid-reviews__list">
        <li className="zaid-reviews__item">
          <img
            src={rev1}
            title="revirwer 1"
            alt="revirwer 1"
            width="140"
            height="140"
            className="zaid-reviews__img"
          />
          <p className="t-20">4.8</p>
          <img src={stars} title="stars" alt="stars" />
          <p className="t-16 fw-500">Robert</p>
          <p className="t-16 zaid-reviews__text">
            Jeśli tak jak ja jesteś doświadczonym graczem, na pewno spodoba ci
            się możliwość rozwijania swoich umiejętności tutaj! Cieszę się, że
            znalazłem miejsce, w którym można śmiało ruszyć w stronę sukcesu i
            uzyskać stabilne wygrane.
          </p>
        </li>
        <li className="zaid-reviews__item">
          <img
            src={rev2}
            title="revirwer 2"
            alt="revirwer 2"
            width="140"
            height="140"
            className="zaid-reviews__img"
          />
          <p className="t-20">4.9</p>
          <img src={stars} title="stars" alt="stars" />
          <p className="t-16 fw-500">Paul</p>
          <p className="t-16 zaid-reviews__text">
            Grając od dłuższego czasu, doceniam to, jak możesz pochwalić się
            tutaj swoimi umiejętnościami! To miejsce naprawdę ułatwia i
            przyspiesza zarabianie pieniędzy i cieszę się, że w końcu znalazłem
            platformę, na której moje umiejętności przynoszą realny zysk.
          </p>
        </li>
        <li className="zaid-reviews__item">
          <img
            src={rev3}
            title="revirwer 3"
            alt="revirwer 3"
            width="140"
            height="140"
            className="zaid-reviews__img"
          />
          <p className="t-20">4.9</p>
          <img src={stars} title="stars" alt="stars" />
          <p className="t-16 fw-500">Mary</p>
          <p className="t-16 zaid-reviews__text">
            Idealna strona dla fanów e-sportu! Od kilku miesięcy biorę udział w
            turniejach i udało mi się wygrać kilka razy! Platforma jest
            przyjazna dla użytkownika i oferuje ciekawe możliwości rozwijania
            umiejętności oraz komunikacji z innymi graczami.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Reviews;
