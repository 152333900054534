import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="zaid-privacy pad">
        <h1>Polityka Prywatności dla zaidimuautomatai.com</h1>
        <p className="t-18">
          Na zaidimuautomatai.com, dostępnej pod adresem zaidimuautomatai.com,
          jednym z naszych priorytetów jest prywatność naszych odwiedzających.
          Niniejszy dokument polityki prywatności zawiera rodzaje informacji,
          które są zbierane i rejestrowane przez zaidimuautomatai.com oraz jak
          je wykorzystujemy.
          <br />
          Jeśli masz dodatkowe pytania lub potrzebujesz więcej informacji na
          temat naszej polityki prywatności, prosimy o kontakt.
        </p>
        <h2>Pliki dziennika</h2>
        <p className="t-18">
          zaidimuautomatai.com stosuje standardową procedurę używania plików
          dziennika. Pliki te rejestrują odwiedzających, gdy odwiedzają strony
          internetowe. Wszystkie firmy hostingowe robią to i jest to część
          analiz usług hostingowych. Informacje zbierane przez pliki dziennika
          obejmują adresy IP, typ przeglądarki, dostawcę usług internetowych
          (ISP), znacznik daty i godziny, strony referencyjne/wyjściowe i
          możliwą liczbę kliknięć. Informacje te nie są powiązane z danymi
          osobowymi. Celem tych informacji jest analiza trendów, zarządzanie
          stroną, śledzenie ruchu użytkowników na stronie oraz zbieranie
          informacji demograficznych. Nasza polityka prywatności została
          stworzona przy użyciu Generatora Polityki Prywatności.
        </p>
        <h2>Nasi partnerzy reklamowi</h2>
        <p className="t-18">
          Niektórzy z reklamodawców na naszej stronie mogą używać plików cookies
          i pikseli śledzących. Nasi partnerzy reklamowi są wymienieni poniżej.
          Każdy z naszych partnerów reklamowych ma swoją własną politykę
          prywatności dotyczącą danych użytkowników. Dla łatwiejszego dostępu
          umieściliśmy poniżej hiperlinki do ich polityki prywatności.
        </p>
        <h2>Polityka prywatności</h2>
        <p className="t-18">
          Możesz zapoznać się z tą listą, aby znaleźć politykę prywatności
          każdego z partnerów reklamowych zaidimuautomatai.com.
          <br />
          Serwery reklamowe osób trzecich lub sieci reklamowe używają
          technologii takich jak cookies, JavaScript lub piksele śledzące, które
          są używane w ich odpowiednich reklamach i linkach pojawiających się na
          zaidimuautomatai.com, które są bezpośrednio wysyłane do przeglądarki
          użytkowników. Otrzymują automatycznie twój adres IP, gdy to następuje.
          Technologie te są używane do mierzenia skuteczności kampanii
          reklamowych i/lub do personalizacji treści reklamowych, które widzisz
          na stronach internetowych, które odwiedzasz.
          <br />
          Należy pamiętać, że zaidimuautomatai.com nie ma dostępu ani kontroli
          nad tymi cookies używanymi przez reklamodawców osób trzecich.
        </p>
        <h2>Polityka prywatności osób trzecich</h2>
        <p className="t-18">
          Polityka prywatności zaidimuautomatai.com nie ma zastosowania do
          innych reklamodawców ani stron internetowych. Dlatego zalecamy
          zapoznanie się z polityką prywatności tych serwerów reklamowych osób
          trzecich w celu uzyskania bardziej szczegółowych informacji. Mogą one
          zawierać ich praktyki oraz instrukcje dotyczące rezygnacji z
          określonych opcji.
          <br />
          Możesz zdecydować się na wyłączenie cookies za pomocą indywidualnych
          ustawień przeglądarki. Aby uzyskać więcej informacji na temat
          zarządzania cookies w poszczególnych przeglądarkach internetowych,
          możesz znaleźć je na stronach internetowych przeglądarek. Czym są
          cookies?
        </p>
        <h2>Informacje dla dzieci</h2>
        <p className="t-18">
          Innym naszym priorytetem jest zapewnienie ochrony dzieciom
          korzystającym z internetu. Zachęcamy rodziców i opiekunów do
          monitorowania, uczestniczenia i/lub kontrolowania oraz kierowania
          aktywnością online ich dzieci.
          <br />
          zaidimuautomatai.com nie gromadzi świadomie danych osobowych dzieci
          poniżej 13 roku życia. Jeśli uważasz, że twoje dziecko dostarczyło
          takie informacje na naszej stronie, zdecydowanie zalecamy
          natychmiastowy kontakt z nami, a my dołożymy wszelkich starań, aby jak
          najszybciej usunąć takie informacje z naszej bazy danych.
        </p>
        <h2>Polityka prywatności online</h2>
        <p className="t-18">
          Niniejsza polityka prywatności dotyczy wyłącznie naszych działań
          online i jest ważna dla odwiedzających naszą stronę internetową w
          odniesieniu do informacji, które udostępnili i/lub gromadzą na
          zaidimuautomatai.com. Polityka ta nie ma zastosowania do informacji
          gromadzonych offline lub za pośrednictwem innych kanałów niż ta strona
          internetowa.
        </p>
        <h2>Zgoda</h2>
        <p className="t-18">
          Korzystając z naszej strony internetowej, wyrażasz zgodę na naszą
          politykę prywatności i akceptujesz jej warunki.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
