import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Games.css";
import game1 from "../../images/21-Blitz-main.webp";
import game2 from "../../images/MAGA-Bingo-main.webp";
import game3 from "../../images/BOOST22-main.webp";
import game4 from "../../images/Blackout-Poker-main.webp";
import game5 from "../../images/Blackout-Bingo-main.webp";
import game6 from "../../images/Yatzy-Royale-main.webp";
import game7 from "../../images/Cribbage-Blitz-main.webp";
import game8 from "../../images/Yatzy-Bingo-main.webp";
import game9 from "../../images/Moneyball-main.webp";
import game10 from "../../images/Cube-Cube-main.webp";

const Games = () => {
  const location = useLocation();
  return (
    <section className="zaid-games pad">
      {location.pathname === "/games" ? (
        <h1 className="zaid-games__header">Nasze gry</h1>
      ) : (
        <h2 className="zaid-games__header">Nasze gry</h2>
      )}
         <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView={2}
          pagination={{ clickable: true }}
          navigation={true}
          spaceBetween={10}
          breakpoints={{
            749: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          className="zaid-games__swiper"
        >
          <SwiperSlide className="zaid-games__slide">
            <img src={game1} title="21 Blitz" alt="21 Blitz" width="255" height="255" className="zaid-games__img"/>
            <Link to="/blitz" className="zaid-games__link t-20">21 Blitz</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game2} title="MAGA Bingo" alt="MAGA Bingo" width="255" height="255" className="zaid-games__img"/>
            <Link to="/maga-bingo" className="zaid-games__link t-20">MAGA Bingo</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game3} title="BOOST22" alt="BOOST22" width="255" height="255" className="zaid-games__img"/>
            <Link to="/boost" className="zaid-games__link t-20">BOOST22</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game4} title="Blackout Poker" alt="Blackout Poker" width="255" height="255" className="zaid-games__img"/>
            <Link to="/blackout-poker" className="zaid-games__link t-20">Blackout Poker</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game5} title="Blackout Bingo" alt="Blackout Bingo" width="255" height="255" className="zaid-games__img"/>
            <Link to="/blackout-bingo" className="zaid-games__link t-20">Blackout Bingo</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game6} title="Yatzy Royale" alt="Yatzy Royale" width="255" height="255" className="zaid-games__img"/>
            <Link to="/yatzy-royale" className="zaid-games__link t-20">Yatzy Royale</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game7} title="Cribbage Blitz" alt="Cribbage Blitz" width="255" height="255" className="zaid-games__img"/>
            <Link to="/cribbage-blitz" className="zaid-games__link t-20">Cribbage Blitz</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game8} title="Yatzy Bingo" alt="Yatzy Bingo" width="255" height="255" className="zaid-games__img"/>
            <Link to="/yatzy-bingo" className="zaid-games__link t-20">Yatzy Bingo</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game9} title="Moneyball" alt="Moneyball" width="255" height="255" className="zaid-games__img"/>
            <Link to="/moneyball" className="zaid-games__link t-20">Moneyball</Link>
          </SwiperSlide>
          <SwiperSlide className="zaid-games__slide">
            <img src={game10} title="Cube Cube" alt="Cube Cube" width="255" height="255" className="zaid-games__img"/>
            <Link to="/cube-cube" className="zaid-games__link t-20">Cube Cube</Link>
          </SwiperSlide>
        </Swiper>
    </section>
  );
};

export default Games;
