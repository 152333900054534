import React from "react";
import "./Main.css";
import About from "../About/About";
import Games from "../Games/Games";
import Faq from "../Faq/Faq";
import Reviews from "../Reviews/Reviews";
import Form from "../Form/Form";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="zaid-main">
      {/* <MainPopup/> */}
      <section className="zaid-main__cover">
        <h1 className="zaid-main__header">
          Rywalizuj w grach zręcznościowych o prawdziwe nagrody pieniężne!
        </h1>
      </section>
      <About />
      <Games />
      <Faq />
      <Reviews />
      <Form />
    </main>
  );
};

export default MainCover;
