import React from "react";
import "./GamePage.css";
import img from "../../images/Cube-Cube-main.webp";
import qr from "../../images/Cube-Cube-qr.webp";
import images from "../../images/Cube-Cube-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameTen = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="Cube Cube"
              alt="Cube Cube"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">Cube Cube</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.6</p>
                </div>
                <p className="t-20">
                  Szybka, zabawna gra dopasowująca, której łatwo się nauczyć,
                  ale trudno opanować. Zawiera rozgrywkę jeden na jednego, która
                  zamienia powolną stratę czasu w ultra-uzależniającą
                  niszczycielkę czasu. Przeciągaj kostki, zdobywaj punkty •
                  Umieść kształty na siatce. To super proste. • Wypełniaj rzędy
                  z boku na bok lub z góry na dół, aby je wyczyścić i zdobyć
                  punkty bonusowe. • Trochę jak Tetris bez grawitacji. Odrobina
                  strategii może wiele zdziałać! • Wyczyść wiele rzędów jednym
                  ruchem, a otrzymasz ogromne bonusy combo. • Wyczyść rzędy w
                  kolejnych turach, a otrzymasz bonusy za serię potworów. • Te
                  bonusy są kluczem do zwycięstwa - ale mogą również zaśmiecić
                  twoją planszę i stać się zwiastunami porażki.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameTen;
