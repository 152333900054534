import React from "react";
import "./GamePage.css";
import img from "../../images/Blackout-Poker-main.webp";
import qr from "../../images/Blackout-Poker-qr.webp";
import images from "../../images/Blackout-Poker-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameFour = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="Blackout Poker"
              alt="Blackout Poker"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">Blackout Poker</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.5</p>
                </div>
                <p className="t-20">
                  Blackout Poker to świeża, towarzyska i konkurencyjna odmiana
                  klasycznej gry, którą można zobaczyć w kasynie w Las Vegas!
                  Graj o prawdziwe pieniądze i prawdziwe nagrody! To prosta gra
                  w 5-kartowy układ. Dobieraj karty, aby uzyskać najlepsze
                  układy, 10 na raz. Każda ręka Jacks or Better punktuje.
                  Najwyższy wynik wygrywa! GŁÓWNE CECHY: - Brak Bad Beatów - W
                  pierwszej tego typu grze nagradza się za grę najlepszą
                  strategią pokera. - The House Never Wins - w grze gracz kontra
                  gracz wygrywają tylko najbardziej utalentowani - Szybka gra w
                  pokera, w którą możesz grać w domu! - Graj z prawdziwymi
                  ludźmi, nie z kasynem! - Powered by Skillz, zdobywaj prawdziwe
                  nagrody i nagrody pieniężne (jeśli są dostępne)
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameFour;
