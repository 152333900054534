import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="zaid-popup-cookie">
        <form className="zaid-popup__form">
          <p className="zaid-popup__header text-center">
            Polityka plików cookie
          </p>
          <p className="zaid-popup__text text-center">
            Używamy plików cookie, aby poprawić Twoje doświadczenia i analizować
            ruch w witrynie. Jeśli będziesz kontynuować przeglądanie, założymy,
            że jesteś z tego zadowolony
          </p>
          <div className="zaid-popup__btn-container">
            <p
              className="zaid-popup__btn-cookie zaid-popup__btn-agree t-20"
              onClick={addPopupCookie}
            >
              Akceptuj
            </p>
            <a href="/cookies" className="zaid-popup__btn-cookie t-20">
              Dowiedz się więcej
            </a>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
