import React from "react";
import "./GamePage.css";
import img from "../../images/MAGA-Bingo-main.webp";
import qr from "../../images/MAGA-Bingo-qr.webp";
import images from "../../images/MAGA-Bingo-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameTwo = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="MAGA Bingo"
              alt="MAGA Bingo"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">MAGA Bingo</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.6</p>
                </div>
                <p className="t-20">
                  To T-R-U-M-P! nie B-I-N-G-O. Dźwięk w tej grze jest ZABAWNY.
                  Włącz dźwięk i ciesz się! Od Śpiącego Joe po sałatkę słowną
                  rechoczącą Kamalę, będziesz się śmiać, rywalizując o nagrody i
                  nagrody pieniężne w wysokości $$, grając z innymi
                  amerykańskimi patriotami.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameTwo;
