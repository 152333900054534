import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="zaid-privacy pad">
        <h1>Polityka Cookies dla zaidimuautomatai.com</h1>
        <p className="t-18">
          To jest polityka cookies dla zaidimuautomatai.com, dostępna od
          zaidimuautomatai.com
        </p>
        <h2>Czym są cookies</h2>
        <p className="t-18">
          Jak jest to zwykle w prawie wszystkich profesjonalnych stronach
          internetowych, ta strona używa cookies, które są małymi plikami
          pobieranymi na twój komputer, aby poprawić twoje doświadczenia. Ta
          strona opisuje, jakie informacje zbierają, jak je wykorzystujemy i
          dlaczego czasami musimy przechowywać te cookies. Opowiemy również, jak
          możesz zapobiec ich przechowywaniu, ale może to wpłynąć na pewne
          elementy funkcjonalności strony lub je „zepsuć”.
        </p>
        <h2>Jak używamy cookies</h2>
        <p className="t-18">
          Używamy cookies z różnych powodów, jak opisano poniżej. Niestety, w
          większości przypadków nie ma standardowych opcji w branży do
          wyłączenia cookies bez całkowitego wyłączenia funkcjonalności i cech,
          które dodają one tej stronie. Zaleca się pozostawienie wszystkich
          cookies, jeśli nie jesteś pewien, czy są potrzebne, w przypadku gdy są
          używane do świadczenia usługi, z której korzystasz.
        </p>
        <h2>Wyłączanie cookies</h2>
        <p className="t-18">
          Możesz zapobiec ustawianiu cookies, dostosowując ustawienia w swojej
          przeglądarce (zobacz Pomoc przeglądarki, aby dowiedzieć się, jak to
          zrobić). Pamiętaj, że wyłączenie cookies wpłynie na funkcjonalność tej
          i wielu innych odwiedzanych przez ciebie stron internetowych.
          Wyłączenie cookies zazwyczaj skutkuje również wyłączeniem pewnych
          funkcji i cech tej strony. Dlatego zaleca się nie wyłączanie cookies.
        </p>
        <h2>Cookies, które ustawiamy</h2>
        <p className="t-18">
          Cookies używane do ustawiania preferencji strony: Aby zapewnić ci
          doskonałe doświadczenia na tej stronie, oferujemy funkcjonalność do
          ustawiania preferencji dotyczących działania tej strony podczas jej
          używania. Aby zapamiętać twoje preferencje, musimy ustawić cookies,
          aby te informacje mogły być odzyskiwane za każdym razem, gdy
          odwiedzasz stronę, której dotyczyły twoje preferencje.
        </p>
        <h2>Cookies osób trzecich</h2>
        <p className="t-18">
          W niektórych specjalnych przypadkach używamy również cookies
          dostarczanych przez zaufane osoby trzecie. Następująca sekcja
          szczegółowo opisuje, jakie cookies osób trzecich możesz napotkać na
          tej stronie.
        </p>
        <p className="t-18">
          Ta strona używa Google Analytics, jednej z najpowszechniej stosowanych
          i najbardziej wiarygodnych rozwiązań analitycznych w sieci, aby pomóc
          nam zrozumieć, jak korzystasz ze strony i w jaki sposób możemy
          poprawić twoje doświadczenia. Te cookies mogą śledzić takie rzeczy,
          jak czas spędzony na stronie i odwiedzane strony, abyśmy mogli nadal
          tworzyć angażujące treści.
        </p>
        <p className="t-18">
          Aby uzyskać więcej informacji na temat cookies Google Analytics,
          odwiedź oficjalną stronę Google Analytics.
        </p>
        <h2>Więcej informacji</h2>
        <p className="t-18">
          Mamy nadzieję, że to wyjaśniło sprawy, i jak wcześniej wspomniano,
          jeśli nie jesteś pewien, czy potrzebujesz cookies, zwykle bezpieczniej
          jest je pozostawić włączone, na wypadek gdyby miały interakcję z jedną
          z funkcji, które wykorzystujesz na naszej stronie.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
