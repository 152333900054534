import React from "react";
import "./About.css";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();

  return (
    <section className="zaid-about pad">
        {location.pathname === "/about-us" ? (
          <h1 className="zaid-about__header">O nas</h1>
        ) : (
          <h2 className="zaid-about__header">O nas</h2>
        )}
        <p className="t-20 text-center">
          Nasz zespół to związek ludzi, których pasją jest świat gier i
          e-sportu. Opracowaliśmy tę platformę, aby dać Ci nie tylko możliwość
          cieszenia się ulubionymi grami, ale także szansę znalezienia świeżych
          emocji i prawdziwych nagród!
        </p>
        <p className="t-20 text-center">
          Stale zwiększamy wybór licencjonowanych gier i dodajemy aktualne
          turnieje z imponującymi nagrodami pieniężnymi. Śledząc najświeższe
          informacje ze świata e-sportu, staramy się zapewnić Ci najbardziej
          odpowiednie i ekscytujące zawody.
        </p>
    </section>
  );
};

export default About;
