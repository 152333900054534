import React from "react";
import "./GamePage.css";
import img from "../../images/BOOST22-main.webp";
import qr from "../../images/BOOST22-qr.webp";
import images from "../../images/BOOST22-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameThree = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="BOOST22"
              alt="BOOST22"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">BOOST22</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">3.2</p>
                </div>
                <p className="t-20">
                  Zagraj w nową grę karcianą. Jest łatwa do nauczenia, szybka i
                  przyjemna w grze.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameThree;
