import React from "react";
import "./Popup.css";
import close from "../../images/close-btn.svg";

const FormPopup = ({ isFormSend, fn }) => {
  return (
    isFormSend && (
      <div className="zaid-popup zaid-form-popup">
        <div className="zaid-form-popup__body">
          <p className="zaid-form-popup__header text-center">
          Dziękujemy za Twoją prośbę, nasz menedżer wkrótce się z Tobą skontaktuje
          </p>
          <img
            src={close}
            title="close btn"
            alt="close btn"
            className="zaid-form-popup__close"
            onClick={() => fn(false)}
          />
        </div>
      </div>
    )
  );
};

export default FormPopup;
