import React, { useState } from "react";
import "./Form.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FormPopup from "../Popup/FormPopup";

const Form = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [isFormSend, setIsFormSend] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
        setFormData({
          name: "",
          email: "",
        });
        setIsFormSend(true);
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="zaid-form pad">
      {location.pathname === "/games" ? (
        <h1 className="zaid-form__header">Kontakty</h1>
      ) : (
        <h2 className="zaid-form__header">Kontakty</h2>
      )}
      <p className="t-20 text-center zaid-form__text">
        Masz pytania, chcesz podzielić się swoimi pomysłami, a następnie po
        prostu zostaw prośbę
      </p>
      <form onSubmit={handleSubmit} className="zaid-form__form">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="imię i nazwisko"
          className="zaid-form__input t-16"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="E-mail"
          className="zaid-form__input t-16"
        />
        <button type="submit" className="zaid-form__btn t-20">
          Aby wysłać
        </button>
      </form>
      <FormPopup isFormSend={isFormSend} fn={setIsFormSend}/>
    </section>
  );
};

export default Form;
