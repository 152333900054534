import React from "react";
import { useLocation } from "react-router-dom";
import "./Faq.css";

const Faq = () => {
  const location = useLocation();

  return (
    <section className="zaid-faq pad">
      {location.pathname === "/faq" ? (
        <h1 className="zaid-faq__header">Najczęściej zadawane pytania:</h1>
      ) : (
        <h2 className="zaid-faq__header">Najczęściej zadawane pytania:</h2>
      )}
      <ul className="zaid-faq__list">
        <li className="zaid-faq__item">
          <p className="t-24">Jak wziąć udział w turnieju?</p>
          <p className="t-20">
            Aby dołączyć do turnieju, po prostu utwórz konto na naszej
            platformie, wybierz preferowaną grę i weź udział w konkursie.
            Zostaniesz dopasowany do innych graczy o podobnym poziomie
            umiejętności.
          </p>
        </li>
        <li className="zaid-faq__item">
          <p className="t-24">Jak wyłaniani są zwycięzcy?</p>
          <p className="t-20">
            Zwycięzcy są określani na podstawie ich wyników w grze. Gracz z
            najwyższym wynikiem lub najszybszym czasem ukończenia, w zależności
            od gry, otrzymuje nagrodę.
          </p>
        </li>
        <li className="zaid-faq__item">
          <p className="t-24">Jakie rodzaje nagród mogę wygrać?</p>
          <p className="t-20">
            Zwycięzcy mogą zdobyć prawdziwe nagrody pieniężne, ekskluzywne
            oferty i inne ekscytujące nagrody. Pula nagród różni się w
            zależności od turnieju.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Faq;
