import React from "react";
import "./GamePage.css";
import img from "../../images/21-Blitz-main.webp";
import qr from "../../images/21-Blitz-qr.webp";
import images from "../../images/21-Blitz-images.webp";
import stars from "../../images/stars-big.svg";
import Form from "../Form/Form";

const GameOne = () => {
  return (
    <main>
      <section className="zaid-game-page">
        <div className="zaid-game-page__body pad">
          <div className="zaid-game-page__top">
            <img
              src={img}
              title="21 Blitz"
              alt="21 Blitz"
              width="400"
              height="400"
              className="zaid-game-page__img"
            />
            <div className="zaid-game-page__text">
              <div className="zaid-game-page__text-left">
                <h1 className="zaid-game-page__header">21 Blitz</h1>
                <div className="zaid-game-page__stars-container">
                  <img
                    src={stars}
                    title="stars"
                    alt="stars"
                    className="zaid-game-page__stars-img"
                  />
                  <p className="zaid-game-page__rating">4.5</p>
                </div>
                <p className="t-20">
                  21 Blitz to połączenie Blackjacka i Solitaire. To doskonały
                  sposób na ćwiczenie umiejętności gry w blackjacka, trenowanie
                  mózgu lub po prostu zabicie czasu. Graj z prawdziwymi ludźmi
                  za darmo, a następnie przejdź do gier gotówkowych, gdy
                  będziesz gotowy do gry i wygrania! Jak grać • Twórz 21 lub
                  stosy po 5 kart, aby zdobywać punkty. • Każda gra ma 1 pełną
                  talię kart, idealne wyzwanie dla początkujących graczy
                  liczących karty. • Skończ talię, zanim skończy się czas, a
                  otrzymasz bonus czasowy. • Znajdź bonusy Combos i Streak, aby
                  udoskonalić swoją strategię.
                </p>
              </div>
              <div className="zaid-game-page__qr-container">
                <p className="zaid-game-page__qr-text">Zainstalować</p>
                <img src={qr} title="qr" alt="qr" width="190" height="181" />
              </div>
            </div>
          </div>
          <div className="zaid-game-page__bottom">
            <img
              src={images}
              title="game images"
              alt="game images"
              width="1240"
              height="400"
              className="zaid-game-page__images"
            />
          </div>
        </div>
      </section>
      <Form />
    </main>
  );
};

export default GameOne;
